import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import Tile from '../components/Tile'

const Imprint = ({data}) => (
            <Layout>

                <div id="main">
                    <div className="content">
                    <h1>
                        {data.impressum.headline}
                    </h1>
                    <div>
                        {data.impressum.body}
                    </div>
                    </div>
               </div>

            </Layout>
)

export const query = graphql`
{
    title: allSanitySettings(filter: {key: {eq: "Title"}}) {
        edges {
        node {
            key
            value
        }
        }
    }
    phone:  allSanitySettings(filter: {key: {eq: "Phone"}}) {
        edges {
        node {
            key
            value
        }
        }
    }
    email: allSanitySettings(filter: {key: {eq: "Email"}}) {
        edges {
        node {
            key
            value
        }
        }
    }
    impressum: sanityPage(headline: {eq: "Impressum"}) {
        headline
        body
    } 
}
`

export default Imprint
