import React from 'react'

const Banner = ({props, node}) => (
    <section id="banner" className="major" style={{
        backgroundImage: `url(https://cdn.sanity.io/` + node.background_image.asset.path + `)`
    }}>
        <div className="inner">
            <div className="head">
            <header className="major">
                <h1>
                    {node.headline}
                </h1>
            </header>
            <div className="content">
                <p>
                    {node.body}
                </p>
                <ul className="actions">
                    <li><a href="#main" className="button next scrolly">Unser Angebot</a></li>
                </ul>
            </div>

            </div>
        </div>
    </section>
)

export default Banner
