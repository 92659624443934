import React from 'react'

const Tile = ({props, node}) => (
   <article style={{
                backgroundImage: `url(https://cdn.sanity.io/` + node.background_image.asset.path + `)`
    }}>
        <header className="major">
            <h3>{node.headline}</h3>
            <p>
                {node.body}
            </p>
        </header>
    </article>
)

export default Tile
